<template>
  <div class="container">

    <!-- First Part -->
    <div class="my-5">

      <h1 class="mb-3">
        {{ $t('policy.title1') }}
      </h1>

      <p> {{ $t('policy.text1_1') }} </p>

      <p>
        {{ $t('policy.text1_2') }}

        <a
          style="color:#ff8f00;"
          @click="mailto()"
        >
          <span>{{ email }}</span>
        </a>

      </p>

      <p> {{ $t('policy.text1_3') }} </p>

      <p> {{ $t('policy.text1_4') }} </p>

      <p> {{ $t('policy.text1_5') }} </p>

    </div>

    <!-- Second Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title2') }}
      </h1>

      <p> {{ $t('policy.text2_1') }} </p>

      <p> {{ $t('policy.text2_2') }} </p>

    </div>

    <!-- Third Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title3') }}
      </h1>

      <h3 class="mt-5">
        {{ $t('policy.subtitle3_1') }}
      </h3>

      <p> {{ $t('policy.text3_1') }} </p>

      <p>
        {{ $t('policy.text3_2') }}
      </p>

      <h3 class="mt-5">
        {{ $t('policy.subtitle3_2') }}
      </h3>

      <p> {{ $t('policy.text3_3') }} </p>

      <ol class="mb-4">

        <li> {{ $t('policy.text3_4') }} </li>

        <li> {{ $t('policy.text3_5') }} </li>

        <li> {{ $t('policy.text3_6') }} </li>

      </ol>

      <h3 class="mt-5">
        {{ $t('policy.subtitle3_3') }}
      </h3>

      <p> {{ $t('policy.text3_7') }} </p>

      <p>
        {{ $t('policy.text3_8') }}
      </p>

      <h3 class="mt-5">
        {{ $t('policy.subtitle3_4') }}
      </h3>

      <p> {{ $t('policy.text3_9') }} </p>

      <ol>

        <li> {{ $t('policy.text3_10') }} </li>

        <li> {{ $t('policy.text3_11') }} </li>

        <li> {{ $t('policy.text3_12') }} </li>

        <li> {{ $t('policy.text3_13') }} </li>

        <li> {{ $t('policy.text3_14') }} </li>

        <li> {{ $t('policy.text3_15') }} </li>

      </ol>

    </div>

    <!-- Fourth Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title4') }}
      </h1>

      <h3 class="mt-5">
        {{ $t('policy.subtitle4_1') }}
      </h3>

      <p> {{ $t('policy.text4_1') }} </p>

      <p>
        {{ $t('policy.text4_2') }}
      </p>

      <h3 class="mt-5">
        {{ $t('policy.subtitle4_2') }}
      </h3>

      <p> {{ $t('policy.text4_3') }} </p>

      <p>
        {{ $t('policy.text4_4') }}
      </p>

      <h3 class="mt-5">
        {{ $t('policy.subtitle4_3') }}
      </h3>

      <p> {{ $t('policy.text4_5') }} </p>

      <p>
        {{ $t('policy.text4_6') }}
      </p>

      <h3 class="mt-5">
        {{ $t('policy.subtitle4_4') }}
      </h3>

      <p> {{ $t('policy.text4_7') }} </p>

      <p> {{ $t('policy.text4_8') }} </p>

      <p> {{ $t('policy.text4_9') }} </p>
    </div>

    <!-- Fifth Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title5') }}
      </h1>

      <p> {{ $t('policy.text5') }} </p>

    </div>

    <!-- Sixth Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title6') }}
      </h1>

      <p> {{ $t('policy.text6_1') }} </p>

      <ol>

        <li> {{ $t('policy.text6_2') }} </li>

        <li> {{ $t('policy.text6_3') }} </li>

        <li> {{ $t('policy.text6_4') }}</li>

        <li> {{ $t('policy.text6_5') }} </li>

      </ol>

      <p> {{ $t('policy.text6_6') }} </p>

      <p> {{ $t('policy.text6_7') }} </p>
    </div>

    <!-- Seventh Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title7') }}
      </h1>

      <p> {{ $t('policy.text7') }} </p>

    </div>

    <!-- Eighth Part -->
    <div class="mb-5">

      <h1 class="mb-3">
        {{ $t('policy.title8') }}
      </h1>

      <p> {{ $t('policy.text8_1') }} </p>

      <p> {{ $t('policy.text8_2') }} </p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TextLegalTerms',
  data() {
    return {
      email: 'info@limmat-group.com',
    }
  },
  methods: {
    mailto() {
      window.open('mailto:info@limmat-group.com', '_blank')
    },
  },
}
</script>

<style scoped>

  h3 {
    font-size: 25px;
    color: #ff8f00 !important;
  }

  span {
    font-size: 19px;
  }

  @media (max-width: 700px){
    h1 {
      font-size: 40px;
    }

    span {
      font-size: 16px;
    }
  }

</style>
