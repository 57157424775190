<template>
  <div>

    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      height="600"
      :title="$t('policy.title')"
    />

    <!-- Text -->
    <text-legal-terms />

  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import TextLegalTerms from 'Components/Policy/TextLegalTerms.vue'

export default {
  name: 'LegalTerms',
  components: {
    Banner,
    TextLegalTerms,
  },
  data() {
    return {
      bannerImage: '/static/limmat-images/policy/legalTerms.jpg',
    }
  },
}
</script>
